import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { FullRectangle } from 'ui/atoms/FullRectangle/FullRectangle';
// eslint-disable-next-line import/no-cycle
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { AnimationMap } from 'features/tableTennis/constants';

export function SetEndAnimation() {
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { sets } = current.context.store.translationData.statistics;

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div className="h-full" ref={nodeRef}>
            <FullRectangle>
                <p className="animation__field--text-action">{translate('time.setFinished')}</p>
                <p className="table-tennis-text-primary-light animation__field--additional-text">
                    {translate('time.set', { set: sets.length - 1 })}
                </p>
            </FullRectangle>
        </div>
    );
}

SetEndAnimation.ANIMATION_NAME = AnimationMap.SetEnd;
