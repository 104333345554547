import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { AnimationMap } from 'features/volleyball/constants';
import { FieldText } from './common/FieldText';

export function AceAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();

    const nodeRef = useNodeTimeline();
    const rectRef = useRectTimeline();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate('animation.ace')}
                team={team}
                icon="ace"
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
        </div>
    );
}

AceAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

AceAnimation.ANIMATION_NAME = AnimationMap.Ace;
