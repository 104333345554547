import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { AnimationMap } from 'features/handball/constants';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function RedCardAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} halfCircle />
            <FieldText
                title={translate('animation.redCard')}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                isWithoutStats
                icon="redCard"
            />
            <FieldIcon animationName="redCard" team={team} />
        </div>
    );
}

RedCardAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};
RedCardAnimation.ANIMATION_NAME = AnimationMap.RedCard;
