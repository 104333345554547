/* eslint-disable import/no-cycle */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCallbackTL, useNodeTimeline, useRectTimeline } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { AnimationMap } from 'features/football/constants';
import { useYellowCardStatistic } from '../hooks';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function YellowCardAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    const { awayStat, homeStat, label: subtext } = useYellowCardStatistic();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} isYellow ref={rectRef} halfCircle />
            <FieldText
                isYellow
                title={translate('action.yellowCard.animation')}
                subText={subtext}
                homeStat={homeStat}
                awayStat={awayStat}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                icon="yellowCard"
            />
            <FieldIcon animationName="yellowCard" team={team} />
        </div>
    );
}

YellowCardAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};
YellowCardAnimation.ANIMATION_NAME = AnimationMap.YellowCard;
