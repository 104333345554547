import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useTargetName } from 'hooks';
import { FieldTextBase } from 'ui';
import { animationDataMap } from 'features/tableTennis/animations/data';
import { FieldIcon } from './FieldIcon';

export function WithRefFieldText(props, ref) {
    const { icon, team, homeTeam, awayTeam, title } = props;

    const isHome = team === 1;

    const { conditions } = useTargetName();

    if (conditions.isVivi || conditions.isMostbetV2) {
        return <FieldTextBase {...props} animationDataMap={animationDataMap} ref={ref} />;
    }

    return (
        <div ref={ref}>
            {!isHome && conditions.isVivi && <FieldIcon team={team} animationName={icon} />}
            <div className={cn('field-text', isHome ? 'field-text--left' : 'field-text--right')}>
                {!isHome && (conditions.isBetandreas || conditions.isMostbet) && (
                    <FieldIcon team={team} animationName={icon} />
                )}
                <div
                    className={cn('field-text__items', isHome ? 'field-text__items--end' : 'field-text__items--start')}
                >
                    <p className="field-text__action">{title}</p>
                    <p className="field-text__team">{isHome ? homeTeam : awayTeam}</p>
                </div>
                {isHome && (conditions.isBetandreas || conditions.isMostbet) && (
                    <FieldIcon team={team} animationName={icon} />
                )}
            </div>
            {isHome && conditions.isVivi && <FieldIcon team={team} animationName={icon} />}
        </div>
    );
}

export const FieldText = React.forwardRef(WithRefFieldText);

WithRefFieldText.propTypes = {
    icon: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
    homeTeam: PropTypes.string.isRequired,
    awayTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
