import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useTargetName } from 'hooks';
import { FieldTextBase } from 'ui';
import { animationDataMap } from 'features/volleyball/animations/data';
import { FieldIcon } from './FieldIcon';

export function WithRefFieldText(props, ref) {
    const { icon, team, homeTeam, awayTeam, title } = props;
    const { conditions } = useTargetName();
    const isHome = team === 1;
    const wrapperCls = cn('field-text_wrapper text-white', {
        _left: isHome,
        _right: !isHome,
        reverse: isHome,
    });

    const txtCls = cn('field-text', {
        'items-end': isHome,
        'items-start': !isHome,
    });

    if (conditions.isVivi || conditions.isMostbetV2) {
        return <FieldTextBase {...props} animationDataMap={animationDataMap} ref={ref} />;
    }

    return (
        <div className={wrapperCls} ref={ref}>
            <FieldIcon team={team} animationName={icon} />
            <div className={txtCls}>
                <p className="action-text">{title}</p>
                <p className={cn('team-text', isHome ? 'team-text__left' : 'team-text__right')}>
                    {isHome ? homeTeam : awayTeam}
                </p>
            </div>
        </div>
    );
}

export const FieldText = React.forwardRef(WithRefFieldText);

WithRefFieldText.propTypes = {
    icon: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
    homeTeam: PropTypes.string.isRequired,
    awayTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
