import React from 'react';
import { useTranslation } from 'react-i18next';

import { useRectTimeline } from 'hooks';
import { FullRectangle } from 'ui/atoms/FullRectangle/FullRectangle';
import { AnimationMap } from 'features/iceHockey/constants';

export function MatchEndAnimation() {
    const [translate] = useTranslation();
    const textRef = useRectTimeline();

    return (
        <>
            <FullRectangle />
            <div className="animation">
                <p className="animation__text" ref={textRef}>
                    {translate('action.matchEnd.animation')}
                </p>
            </div>
        </>
    );
}

MatchEndAnimation.ANIMATION_NAME = AnimationMap.MatchEnd;
