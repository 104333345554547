import React from 'react';
import { useTranslation } from 'react-i18next';
import { Lottie, useLottie } from 'react-lottie-hook';

import { useCallbackTL, useNodeTimeline, useTargetName } from 'hooks';
import { FullRectangle } from 'ui/atoms/FullRectangle/FullRectangle';
import { animationDataMap } from 'features/volleyball/animations/data';
import { AnimationMap } from 'features/volleyball/constants';

export function TimeoutAnimation() {
    const [translate] = useTranslation();
    const { conditions } = useTargetName();

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
            viewBoxSize: conditions.isVivi ? '-5 0 65 65' : '',
        },
        animationData: animationDataMap.timeout,
    });

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div ref={nodeRef}>
            <FullRectangle />
            <div className="playing_status">
                <div className="playing_status--icon">
                    <Lottie lottieRef={lottieRef} width="100%" height="100%" />
                </div>
                <div className="playing_status--text">
                    <p>{translate('animation.timeout')}</p>
                </div>
            </div>
        </div>
    );
}

TimeoutAnimation.ANIMATION_NAME = AnimationMap.Timeout;
