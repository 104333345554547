/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */

import * as React from 'react';
import PropTypes from 'prop-types';

import { useTargetName } from 'hooks';

const MostBetVariant = ({ subtype }) => {
    const colorMap = {
        yellow: '#F1E469',
        red: '#E4A6A6',
    };

    return (
        <svg width={28} height={25} viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 1H1v14h5v-5h2v5h4V1Z" fill={colorMap[subtype]} />
            <path
                d="M14 6c-.35 0-.69.07-1 .18V0H0v16h4v8h8.48L17 18.35V9c0-1.65-1.35-3-3-3ZM2 14V2h9v12h-1v-3c0-1.65-1.35-3-3-3s-3 1.35-3 3v3H2Zm13 3.65L11.52 22H6V11c0-.55.45-1 1-1s1 .45 1 1v5h5V9c0-.55.45-1 1-1s1 .45 1 1v8.65Z"
                fill="#305E17"
            />
        </svg>
    );
};

const BetAndreasVariant = ({ subtype }) => {
    const colorMap = {
        yellow: '#FFD900',
        red: '#C94A61',
    };

    return (
        <svg width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={colorMap[subtype]}
                d="M17.076 4.718 15.98 4.26v7.388l1.988-4.794a1.638 1.638 0 0 0-.892-2.136ZM1.12 7.745l4.059 9.778a1.644 1.644 0 0 0 1.48 1.03c.213 0 .434-.04.647-.13l6.03-2.496c.614-.253.99-.859 1.006-1.464a1.872 1.872 0 0 0-.106-.663l-4.091-9.777A1.598 1.598 0 0 0 8.665 3c-.213 0-.425.05-.63.123L2.013 5.618a1.631 1.631 0 0 0-.892 2.127Zm13.214-3.109A1.636 1.636 0 0 0 12.699 3h-1.187l2.823 6.824"
            />
        </svg>
    );
};
const ViviVariant = ({ subtype }) => {
    const colorMap = {
        yellow: '#FFC131',
        red: '#ED2E14',
    };

    return (
        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd">
                <path
                    d="M.1 2.8C.1 1.32 1.32.1 2.8.1h3.9c1.48 0 2.7 1.22 2.7 2.7v4.4a.5.5 0 0 1-1 0V2.8c0-.92-.78-1.7-1.7-1.7H2.8c-.92 0-1.7.78-1.7 1.7v7.1c0 .92.78 1.7 1.7 1.7h1.4a.5.5 0 0 1 0 1H2.8A2.72 2.72 0 0 1 .1 9.9V2.8Z"
                    fill={colorMap[subtype]}
                />
                <path
                    d="M10.61 4.59a.5.5 0 0 1 .7-.08c.5.4 1.12.92 1.64 1.44 1.93 1.92 2.34 4.26 2.64 5.96.1.54.19 1.41.23 2.13a57.03 57.03 0 0 1 .08 1.23v.11a.5.5 0 0 1-1 .04v-.1a51.03 51.03 0 0 0-.07-1.21c-.05-.73-.13-1.55-.22-2.01v-.01c-.3-1.7-.69-3.76-2.36-5.44-.48-.48-1.07-.96-1.56-1.36a.5.5 0 0 1-.08-.7Zm.23 3.48a.5.5 0 0 1 .69.17 2.86 2.86 0 0 1 .36.94v.07l.01.02v.02l-.5.01h.5v1.1a.5.5 0 0 1 0 .07c-.17 1.14-1.6 1.46-2.27.67a6.02 6.02 0 0 0-.2-.18 22.65 22.65 0 0 0-.93-.79 3 3 0 0 0-.3-.21 1.15 1.15 0 0 0-.73-.06.64.64 0 0 0-.4.36c-.03.15-.04.26-.03.35a.94.94 0 0 0 .24.55l.14.15c1.2.8 1.73 1.54 2.15 2.2l.1.16c.36.56.61.96 1.19 1.3a.5.5 0 1 1-.52.86c-.78-.47-1.15-1.06-1.5-1.61l-.11-.18a5.8 5.8 0 0 0-1.98-1.99h-.01a2.6 2.6 0 0 1-.49-.63 1.93 1.93 0 0 1-.1-1.52c.2-.47.55-.8 1.01-.95a2.16 2.16 0 0 1 1.46.1c.15.08.33.2.49.33a13.73 13.73 0 0 1 1.28 1.1c.06.08.17.12.3.09.1-.03.19-.1.21-.21V9.3l-.02-.1c-.02-.08-.08-.24-.2-.45a.5.5 0 0 1 .16-.69Z"
                    fill="#444188"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h16v16H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};
const Mostbet2Variant = ({ subtype }) => {
    const colorMap = {
        yellow: '#FFC131',
        red: '#ED2E14',
    };

    return (
        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.85886 1.94118L1.10346 4.72162C1.04298 4.76802 0.967357 4.89536 1.00642 5.11866L4.95908 12.0519C4.96366 12.06 4.96802 12.0681 4.97215 12.0764C5.01706 12.1664 5.06254 12.1872 5.08703 12.1942C5.11711 12.2028 5.18364 12.2083 5.2882 12.1588L10.0612 9.3681C10.0704 9.36269 10.0798 9.35758 10.0894 9.35278C10.1792 9.30777 10.2 9.26218 10.207 9.23763C10.2156 9.20731 10.2211 9.14007 10.1708 9.03426L6.18798 2.04806C6.1834 2.04003 6.17905 2.03187 6.17492 2.0236C6.13001 1.93357 6.08453 1.91277 6.06004 1.90576C6.02995 1.89714 5.96343 1.89165 5.85886 1.94118ZM7.06035 1.56269C6.90512 1.25967 6.65402 1.03584 6.33412 0.944231C6.01619 0.853182 5.6877 0.90876 5.40033 1.05278C5.39075 1.05758 5.38132 1.06269 5.37207 1.0681L0.583213 3.8681C0.574605 3.87314 0.566149 3.87843 0.557857 3.88397C0.0258249 4.23948 -0.0805778 4.89246 0.0513149 5.42126C0.062389 5.46566 0.0794925 5.50833 0.102146 5.54806L4.08672 12.5373C4.24194 12.8403 4.49304 13.0641 4.81294 13.1558C5.13088 13.2468 5.45936 13.1912 5.74674 13.0472C5.75632 13.0424 5.76575 13.0373 5.775 13.0319L10.5515 10.2391C10.8527 10.0835 11.0751 9.83222 11.1663 9.51235C11.2571 9.19368 11.2017 8.86443 11.058 8.57639C11.0539 8.56811 11.0495 8.55996 11.0449 8.55192L7.06035 1.56269Z"
                fill={colorMap[subtype]}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.90531 1.90815C9.84282 1.92485 9.79139 1.96378 9.76153 2.02364C9.75775 2.03121 9.75378 2.03869 9.74962 2.04606L8.59403 4.09532L11.1362 8.43656C11.4876 8.98327 11.3 9.80246 10.652 10.139L8.98314 11.1148L10.6772 12.176L10.6895 12.184C10.703 12.193 10.7508 12.2105 10.8205 12.1919C10.883 12.1752 10.9344 12.1363 10.9643 12.0764C10.9684 12.0682 10.9728 12.06 10.9774 12.052L14.9681 5.05196C14.9738 5.042 14.9798 5.03223 14.9861 5.02268C14.9951 5.0092 15.0126 4.96122 14.994 4.89136C14.9774 4.82873 14.9385 4.77718 14.8788 4.74725C14.8692 4.74244 14.8598 4.73733 14.8505 4.73192L10.0617 1.93192C10.0531 1.92689 10.0446 1.9216 10.0363 1.91606C10.0229 1.90707 9.975 1.88952 9.90531 1.90815ZM10.5752 1.07449C10.0288 0.722288 9.21016 0.91172 8.87563 1.56367L7.5841 3.854C7.49658 4.00921 7.49813 4.19938 7.58816 4.35313L10.2819 8.95313C10.2867 8.96136 10.2918 8.96945 10.297 8.97738C10.306 8.99086 10.3235 9.03885 10.3049 9.1087C10.2883 9.17134 10.2494 9.22289 10.1897 9.25282C10.1801 9.25762 10.1707 9.26273 10.1614 9.26814L7.76702 10.6681C7.61611 10.7564 7.52226 10.9173 7.51959 11.0924C7.51692 11.2675 7.60584 11.4312 7.75399 11.524L10.1432 13.0207C10.6892 13.3793 11.5132 13.1915 11.8496 12.5376L15.8271 5.56067C16.176 5.01397 15.9879 4.19702 15.3411 3.86105L10.5752 1.07449Z"
                fill={colorMap[subtype]}
            />
        </svg>
    );
};

const Card = (props) => {
    const { classMap: icon } = useTargetName((targets) => ({
        [targets.MOSTBET]: <MostBetVariant {...props} />,
        [targets.BETANDREAS]: <BetAndreasVariant {...props} />,
        [targets.VIVI]: <ViviVariant {...props} />,
        [targets['MOSTBET-2']]: <Mostbet2Variant {...props} />,
    }));

    return icon;
};
Card.propTypes = {
    subtype: PropTypes.string,
};
export default Card;
