import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { HalfRectangle } from 'ui';
import { AnimationMap } from 'features/basketball3x3/constants';
import { useFoulStatistics } from 'features/basketball3x3/hooks';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function FoulAnimation(props) {
    const { team } = props;

    const [translate] = useTranslation();
    const { awayStat, homeStat, label: subText } = useFoulStatistics();
    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} halfCircle />
            <FieldText
                title={translate('action.foul.animation')}
                subText={subText}
                team={team}
                homeStat={homeStat}
                awayStat={awayStat}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                icon={AnimationMap.Foul}
            />
            <FieldIcon animationName={AnimationMap.Foul} team={team} />
        </div>
    );
}

FoulAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

FoulAnimation.ANIMATION_NAME = AnimationMap.Foul;
