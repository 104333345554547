import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { HalfRectangle } from 'ui';
import { AnimationMap } from 'features/basketball3x3/constants';
import { useBallPossessionStatistic } from 'features/basketball3x3/hooks';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function MissAnimation(props) {
    const { team } = props;

    const [translate] = useTranslation();
    const { label: subText, homeStat, awayStat } = useBallPossessionStatistic();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} halfCircle />
            <FieldText
                title={translate('action.miss.animation')}
                subText={subText}
                team={team}
                homeStat={homeStat}
                awayStat={awayStat}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                isPercent
                icon={AnimationMap.FreeThrowNotScored}
            />
            <FieldIcon team={team} animationName={AnimationMap.FreeThrowNotScored} />
        </div>
    );
}

MissAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

MissAnimation.ANIMATION_NAME = AnimationMap.Miss;
