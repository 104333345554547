/* eslint-disable max-len */
import * as React from 'react';

import { useTargetName } from 'hooks';

const Removal = (props) => {
    const { classMap: icon } = useTargetName((targets) => ({
        [targets.MOSTBET]: (
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M10 0C4.49 0 0 4.49 0 10s4.49 10 10 10 10-4.49 10-10S15.51 0 10 0Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm4.24-10.83L11.41 10l2.83 2.83a.996.996 0 0 1-.71 1.7c-.26 0-.51-.1-.71-.29L10 11.41l-2.83 2.83c-.2.2-.45.29-.71.29-.26 0-.51-.1-.71-.29a.996.996 0 0 1 0-1.41L8.59 10 5.76 7.17a.996.996 0 1 1 1.41-1.41L10 8.59l2.83-2.83a.996.996 0 1 1 1.41 1.41Z"
                    fill="#256569"
                />
            </svg>
        ),
        [targets.BETANDREAS]: (
            <svg {...props} width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10 2a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm3.24 7.17L11.41 11l1.83 1.83a1 1 0 0 1-.326 1.626 1 1 0 0 1-.384.074 1 1 0 0 1-.71-.29L10 12.41l-1.83 1.83a1 1 0 0 1-.71.29 1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.41L8.59 11 6.76 9.17a1 1 0 1 1 1.41-1.41L10 9.59l1.83-1.83a1 1 0 1 1 1.41 1.41Z"
                    fill="#9393A9"
                />
            </svg>
        ),
        [targets.VIVI]: (
            <svg {...props} width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.15 9.23a6.15 6.15 0 1 0-12.3 0 6.15 6.15 0 0 0 12.3 0ZM4.54.63h4.92M11.92 3.08l-1.23 1.23M5 11l4-4M5 7l4 4"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
        [targets['MOSTBET-2']]: (
            <svg {...props} width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M12.02 4.65L12.89 3.78C13.09 3.58 13.09 3.27 12.89 3.07C12.69 2.87 12.38 2.87 12.18 3.07L11.31 3.94C10.23 3.01 8.86 2.42 7.36 2.31V1H8.13C8.41 1 8.63 0.78 8.63 0.5C8.63 0.22 8.41 0 8.13 0H5.59C5.31 0 5.09 0.22 5.09 0.5C5.09 0.78 5.31 1 5.59 1H6.36V2.31C4.85 2.42 3.47 3.02 2.38 3.96L1.49 3.07C1.29 2.87 0.98 2.87 0.78 3.07C0.58 3.27 0.58 3.58 0.78 3.78L1.67 4.67C0.63 5.87 0 7.43 0 9.15C0 12.93 3.08 16.01 6.86 16.01C10.64 16.01 13.72 12.93 13.72 9.15C13.72 7.43 13.08 5.86 12.03 4.65H12.02ZM6.86 15C3.63 15 1 12.37 1 9.14C1 5.91 3.63 3.29 6.86 3.29C10.09 3.29 12.72 5.92 12.72 9.15C12.72 12.38 10.09 15.01 6.86 15.01V15Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.64645 11.3536C4.84171 11.5489 5.15829 11.5489 5.35355 11.3536L9.35359 7.35355C9.54886 7.15829 9.54886 6.84171 9.35359 6.64645C9.15833 6.45118 8.84175 6.45118 8.64649 6.64645L4.64645 10.6465C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.64645 6.64649C4.45118 6.84175 4.45118 7.15833 4.64645 7.35359L8.64649 11.3536C8.84175 11.5489 9.15833 11.5489 9.35359 11.3536C9.54886 11.1584 9.54886 10.8418 9.35359 10.6465L5.35355 6.64649C5.15829 6.45123 4.84171 6.45123 4.64645 6.64649Z"
                    fill="white"
                />
            </svg>
        ),
    }));

    return icon;
};

export default Removal;
