import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { AnimationMap } from 'features/bigTennis/constants';
import { FieldText } from './common/FieldText';

export function ChallengeAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();

    const nodeRef = useNodeTimeline(2);
    const rectRef = useRectTimeline(2);

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} />
            <FieldText
                title={translate('animation.challenge')}
                team={team}
                icon="challenge"
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
            />
        </div>
    );
}

ChallengeAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

ChallengeAnimation.ANIMATION_NAME = AnimationMap.Challenge;
