import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { FullRectangle } from 'ui/atoms/FullRectangle/FullRectangle';
import { AnimationMap } from 'features/bigTennis/constants';

export function TennisGameEndAnimation() {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div className="text-white font-medium" ref={nodeRef}>
            <FullRectangle />
            <div className="abs-center items-center">
                <p className="animation__field--text-action">{translate('animation.gamePlayed')}</p>
            </div>
        </div>
    );
}

TennisGameEndAnimation.ANIMATION_NAME = AnimationMap.TennisGameEnd;
