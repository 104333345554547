import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNodeTimeline } from 'hooks';
import { FullRectangle } from 'ui';
import { AnimationMap } from 'features/basketball3x3/constants';

export function MatchEndAnimation() {
    const nodeRef = useNodeTimeline();
    const [translate] = useTranslation();

    return (
        <div ref={nodeRef}>
            <FullRectangle />
            <div className="playing_status">
                <div className="playing_status--title">
                    <p>{translate('action.matchEnd.animation')}</p>
                </div>
            </div>
        </div>
    );
}

MatchEndAnimation.ANIMATION_NAME = AnimationMap.MatchEnd;
