import React from 'react';
import { useTranslation } from 'react-i18next';

import { FullRectangle } from 'ui/atoms/FullRectangle/FullRectangle';
import { AnimationMap } from 'features/iceHockey/constants';
import { IconText } from './common/IconText';

export function PlayersWarmUpAnimation() {
    const [translate] = useTranslation();

    return (
        <>
            <FullRectangle />
            <IconText icon="playersWarmUp" text={translate('action.playersWarmUp.animation')} />
        </>
    );
}

PlayersWarmUpAnimation.ANIMATION_NAME = AnimationMap.PlayersWarmUp;
