import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/football/constants';
import { useFreeKickStatistic } from 'features/football/hooks';
import { FieldAttackArrow } from './common/FieldAttackArrow';
import { FieldBallPoint } from './common/FieldBallPoint';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function ShotOnGoalAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    const { label: subtext, homeStat, awayStat } = useFreeKickStatistic('statistics.shotsOnGoal');

    return (
        <div ref={nodeRef}>
            <FieldAttackArrow isLong team={team} ref={rectRef} />
            <FieldText
                title={translate('action.shotOnGoal.animation')}
                subText={subtext}
                homeStat={homeStat}
                awayStat={awayStat}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                icon="kickFromGoal"
            />
            <FieldIcon animationName="kickFromGoal" team={team} />
            <FieldBallPoint isLong team={team} />
        </div>
    );
}

ShotOnGoalAnimation.ANIMATION_NAME = AnimationMap.ShotOnGoal;

ShotOnGoalAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};
