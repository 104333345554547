import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { Cross } from 'ui/atoms/Icons/Cross';
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { AnimationMap } from 'features/futsal/constants';
import { FieldIcon } from './common/FieldIcon';
import { FieldStatus } from './common/FieldStatus';
import { FieldText } from './common/FieldText';

export function PenaltyMissAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { homeTeam: HOME, awayTeam: AWAY } = store;
    const { statistics } = store.translationData;
    const { penaltyShootout } = statistics;
    const homePenalties = penaltyShootout[HOME].length;
    const awayPenalties = penaltyShootout[AWAY].length;

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} halfCircle />
            <FieldText
                title={translate('action.penaltyMiss.animation')}
                subText={translate('statistics.penalty')}
                team={team}
                homeStat={homePenalties}
                awayStat={awayPenalties}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                icon="penaltyMiss"
            />
            <FieldIcon animationName="penaltyMiss" team={team} />
            <FieldStatus team={team}>
                <Cross className="fill-current h-4 w-4 sm:h-7 sm:w-7" />
            </FieldStatus>
        </div>
    );
}

PenaltyMissAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

PenaltyMissAnimation.ANIMATION_NAME = AnimationMap.PenaltyMiss;
