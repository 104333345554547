/* eslint-disable max-classes-per-file */
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCallbackTL, useNodeTimeline, useRectTimeline } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { AnimationMap, outPositions } from 'features/football/constants';
import { useOutStatistic } from 'features/football/hooks';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';
import { OutBallPoint } from './common/OutBallPoint';

export function OutAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();
    const rectRef = useRectTimeline();

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    const { ballPoint, awayStat, homeStat, label } = useOutStatistic();

    function getOutPosition() {
        let position = '';

        if (!ballPoint) {
            return position;
        }

        const { x, y } = ballPoint;
        const half = 50;
        const top = 0;
        const bottom = 100;

        if (x > half && (y === top || y < half)) {
            position = outPositions.topRight;
        } else if (x < half && (y === top || y < half)) {
            position = outPositions.topLeft;
        } else if (x > half && (y === bottom || y > half)) {
            position = outPositions.bottomRight;
        } else if (x < half && (y === bottom || y > half)) {
            position = outPositions.bottomLeft;
        } else {
            position = outPositions.unknown;
        }

        return position;
    }

    return (
        <div ref={nodeRef} className="out-animation-wrap">
            <HalfRectangle team={team} ref={rectRef} halfCircle />
            <FieldText
                title={translate('action.throwIn.animation')}
                subText={label}
                homeStat={homeStat}
                awayStat={awayStat}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                icon="out"
            />
            <FieldIcon animationName="out" team={team} />
            <OutBallPoint team={team} position={getOutPosition()} />
        </div>
    );
}

OutAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

OutAnimation.ANIMATION_NAME = AnimationMap.Out;

export function OutWithPositionAnimation(props) {
    return <OutAnimation {...props} />;
}
OutWithPositionAnimation.ANIMATION_NAME = AnimationMap.OutWithPosition;
