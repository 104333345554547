import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { AnimationMap, ModelMap } from 'features/rugby/constants';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function CardAnimation(props) {
    const { team, type } = props;
    const [translate] = useTranslation();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();
    const cardType = ModelMap.RedCard === type ? 'redCard' : 'yellowCard';

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} halfCircle />
            <FieldText
                title={translate(`action.${cardType}.animation`)}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                isWithoutStats
                icon={cardType}
            />
            <FieldIcon animationName={cardType} team={team} />
        </div>
    );
}

CardAnimation.propTypes = {
    team: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
};
CardAnimation.ANIMATION_NAME = AnimationMap.Card;
