import React from 'react';
import { useTranslation } from 'react-i18next';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';

import { useNodeTLCallback } from 'hooks';
import { FullRectangle } from 'ui/atoms/FullRectangle/FullRectangle';
import { animationDataMap } from 'features/iceHockey/animations/data';

export function IconText(props) {
    const { text, icon } = props;
    const [translate] = useTranslation();

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid',
            progressiveLoad: false,
        },
        animationData: animationDataMap[icon],
    });

    const nodeRef = useNodeTLCallback();

    return (
        <div ref={nodeRef}>
            <FullRectangle />
            <div className="playing_status">
                <div className="playing_status--icon">
                    <Lottie lottieRef={lottieRef} width="100%" height="100%" />
                </div>
                <div className="playing_status--text">
                    <p>{translate(text)}</p>
                </div>
            </div>
        </div>
    );
}

IconText.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
};
