import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { AnimationMap } from 'features/futsal/constants';
import { FieldAttackArrow } from './common/FieldAttackArrow';
import { FieldBallPoint } from './common/FieldBallPoint';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function AttackAnimation(props) {
    // todo [drone] maybe need to remove
    const { team } = props;

    const [translate] = useTranslation();
    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { statistics } = store.translationData;
    const { ballPossessionData } = statistics;

    const totalPossessionTime = (teamNumber, stats) =>
        stats[teamNumber].reduce((acc, curr) => acc + curr.possessionTime, 0);

    const stats = {
        home: 50,
        away: 50,
    };

    const homePossession = totalPossessionTime(1, ballPossessionData);
    const awayPossession = totalPossessionTime(2, ballPossessionData);
    const totalPossession = homePossession + awayPossession;

    stats.homeStat = totalPossession ? Math.round((homePossession / totalPossession) * 100) : 50;
    stats.awayStat = totalPossession ? 100 - stats.homeStat : 50;

    return (
        <div ref={nodeRef}>
            <FieldAttackArrow team={team} ref={rectRef} />
            <FieldText
                title={translate('action.attack.animation')}
                subText={translate('statistics.possession')}
                team={team}
                homeStat={stats.homeStat}
                awayStat={stats.awayStat}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                isPercent
                icon="attack"
            />
            <FieldIcon animationName="attack" team={team} />
            <FieldBallPoint team={team} />
        </div>
    );
}

AttackAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};
AttackAnimation.ANIMATION_NAME = AnimationMap.Attack;
