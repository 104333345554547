import React, { useContext, useEffect, useState } from 'react';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { CountdownAnimation, FullRectangle } from 'ui';
import { AnimationMap } from 'features/basketball/constants';
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { formattedTimer } from 'features/common/utils';

export function PeriodCountdownAnimation() {
    const { current } = useContext(RootMachineContext);
    const { store } = current.context;
    const { countdown } = store;

    const statusText = {
        setWillBegin: 'Игра начнётся через',
        setBeginSoon: 'Игра скоро начнётся',
    };

    const [status, setStatus] = useState(statusText.setWillBegin);
    const [showCountdown, setShowCountdown] = useState(false);
    const [countdownText, setCountdownText] = useState('');

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);
    useEffect(() => {
        const currentTime = new Date().getTime();
        const countDownTime = currentTime + countdown.countdown;

        let timerId;

        function startTimer() {
            timerId = setInterval(() => {
                const updatedTime = new Date().getTime();
                const timer = Math.floor((countDownTime - updatedTime) / 1000);

                if (timer > 0) {
                    const formattedTime = formattedTimer(timer);
                    setCountdownText(formattedTime);
                } else {
                    clearInterval(timerId);
                    setShowCountdown(false);
                    setStatus(statusText.setBeginSoon);
                }
            }, 1000);
        }

        if (countdown.countdown > 0) {
            const timer = Math.floor((countDownTime - currentTime) / 1000);
            const formattedTime = formattedTimer(timer);
            setCountdownText(formattedTime);
            setShowCountdown(true);
            setStatus(statusText.setWillBegin);
            startTimer();
        } else {
            setShowCountdown(false);
            setStatus(statusText.setBeginSoon);
        }

        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const countdownAnimation = (
        <div className="period-countdown" ref={nodeRef}>
            <FullRectangle>
                {showCountdown && <p className="period-countdown__text">{countdownText}</p>}
                <p className="period-countdown__status">{status}</p>
            </FullRectangle>
        </div>
    );

    return (
        <CountdownAnimation
            showCountdown={showCountdown}
            defaultComponent={countdownAnimation}
            status={status}
            countdownText={countdownText}
        />
    );
}

PeriodCountdownAnimation.ANIMATION_NAME = AnimationMap.PeriodCountdown;
