import React from 'react';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useTeamNumbers } from 'hooks';

export function FieldIconBase({ animationName, animationDataMap, team }) {
    const [homeTeamNumber] = useTeamNumbers();
    const isHome = homeTeamNumber ? team === homeTeamNumber : team === 1;

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
        animationData: animationDataMap[animationName],
    });

    return (
        <div className={cn('field-icon', isHome ? 'field-icon--end' : 'field-icon--start')}>
            <div
                className={cn(
                    'field-icon__transform',
                    isHome ? 'field-icon__transform--end' : 'field-icon__transform--start',
                )}
            >
                <Lottie lottieRef={lottieRef} width="100%" height="100%" />
            </div>
        </div>
    );
}

FieldIconBase.propTypes = {
    animationName: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
    animationDataMap: PropTypes.shape({}).isRequired,
};
