import advertisingTimeout from './advertisingTimeout.json';
import attack from './attack.json';
import emptyGate from './emptyGate.json';
import faceOff from './faceOff.json';
import fight from './fight.json';
import goal from './goal.json';
import goalkeeperInGate from './goalkeeperInGate.json';
import missedGoalKick from './missedGoalKick.json';
import penalty from './penalty.json';
import penaltyEnd from './penaltyEnd.json';
import penaltyShot from './penaltyShot.json';
import penaltyStart from './penaltyStart.json';
import playersOnField from './playersOnField.json';
import playersWarmUp from './playersWarmUp.json';
import removal from './removal.json';
import removalEnd from './removalEnd.json';
import shotOnGoal from './shotOnGoal.json';
import timeout from './timeout.json';
import videoReferee from './videoReferee.json';

export const animationDataMap = {
    attack,
    removal,
    emptyGate,
    faceOff,
    fight,
    goal,
    goalkeeperInGate,
    missedGoalKick,
    penalty,
    penaltyStart,
    penaltyShot,
    penaltyEnd,
    playersOnField,
    playersWarmUp,
    removalEnd,
    shotOnGoal,
    videoReferee,
    timeout,
    advertisingTimeout,
};
