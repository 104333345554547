import React from 'react';
import PropTypes from 'prop-types';

import { useTargetName } from 'hooks';
import { FieldTextBase } from 'ui';
import { animationDataMap } from 'features/handball/animations/data';

function WithRefFieldTextLeft(props, ref) {
    const { homeStat, awayStat, homeTeam, isPercent, title, subText, isWithoutStats } = props;

    return (
        <div className="field_text-wrapper">
            <div className="field_text _left" ref={ref}>
                <div className="field_text-info">
                    <p className="field_text-action animation__field--text-action">{title}</p>
                    <p className="field_text-team _left">{homeTeam}</p>
                </div>
                {!isWithoutStats && (
                    <div className="field_text-stats _left">
                        <div className="flex justify-end">
                            <p>
                                <span>{`${subText} `}</span>
                                <span>{homeStat}</span>
                                {isPercent && <span>%</span>}
                            </p>
                            <span className="divider" />
                            <p className="field_text-away">
                                <span>{awayStat} </span>
                                {isPercent && <span>%</span>}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const FieldTextLeft = React.forwardRef(WithRefFieldTextLeft);

WithRefFieldTextLeft.propTypes = {
    homeStat: PropTypes.number.isRequired,
    awayStat: PropTypes.number.isRequired,
    homeTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    isPercent: PropTypes.bool,
    isWithoutStats: PropTypes.bool,
};

WithRefFieldTextLeft.defaultProps = {
    isPercent: false,
    isWithoutStats: false,
};

function WithRefFieldTextRight(props, ref) {
    const { homeStat, awayStat, awayTeam, isPercent, title, subText, isWithoutStats } = props;

    return (
        <div className="field_text-wrapper _right">
            <div className="field_text _right" ref={ref}>
                <div className="field_text-info">
                    <p className="field_text-action animation__field--text-action">{title}</p>
                    <p className="field_text-team _right">{awayTeam}</p>
                </div>
                {!isWithoutStats && (
                    <div className="field_text-stats _right">
                        <div className="flex">
                            <p className="field_text-away">
                                <span>{homeStat}</span>
                                {isPercent && <span>%</span>}
                            </p>
                            <span className="divider" />
                            <p>
                                <span>{`${subText} `}</span>
                                <span>{awayStat}</span>
                                {isPercent && <span>%</span>}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const FieldTextRight = React.forwardRef(WithRefFieldTextRight);

WithRefFieldTextRight.propTypes = {
    homeStat: PropTypes.number.isRequired,
    awayStat: PropTypes.number.isRequired,
    awayTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subText: PropTypes.string.isRequired,
    isPercent: PropTypes.bool,
    isWithoutStats: PropTypes.bool,
};

WithRefFieldTextRight.defaultProps = {
    isPercent: false,
    isWithoutStats: false,
};

function WithRefFieldText(props, ref) {
    const { team, ...rest } = props;

    const { conditions } = useTargetName();

    if (conditions.isVivi || conditions.isMostbetV2) {
        return <FieldTextBase {...props} animationDataMap={animationDataMap} ref={ref} />;
    }

    return <>{team === 1 ? <FieldTextLeft {...rest} ref={ref} /> : <FieldTextRight {...rest} ref={ref} />}</>;
}
export const FieldText = React.forwardRef(WithRefFieldText);

WithRefFieldText.propTypes = {
    team: PropTypes.number.isRequired,
};
