import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useTargetName, useTeamNumbers } from 'hooks';
import { FieldIconBase } from '../FieldIconBase';

export const FieldTextBase = React.forwardRef(({ icon, team, homeTeam, awayTeam, title, animationDataMap }, ref) => {
    const [homeTeamNumber] = useTeamNumbers();
    const isHome = homeTeamNumber ? team === homeTeamNumber : team === 1;

    const { conditions } = useTargetName();

    return (
        <div ref={ref}>
            {!isHome && (conditions.isVivi || conditions.isMostbetV2) && (
                <FieldIconBase team={team} animationName={icon} animationDataMap={animationDataMap} />
            )}
            <div className={cn('field-text', isHome ? 'field-text--left' : 'field-text--right')}>
                {!isHome && (conditions.isBetandreas || conditions.isMostbet) && (
                    <FieldIconBase team={team} animationName={icon} animationDataMap={animationDataMap} />
                )}
                <div
                    className={cn('field-text__items', isHome ? 'field-text__items--end' : 'field-text__items--start')}
                >
                    <p className="field-text__action">{title}</p>
                    <p className="field-text__team">{isHome ? homeTeam : awayTeam}</p>
                </div>
                {isHome && (conditions.isBetandreas || conditions.isMostbet) && (
                    <FieldIconBase team={team} animationName={icon} animationDataMap={animationDataMap} />
                )}
            </div>
            {isHome && (conditions.isVivi || conditions.isMostbetV2) && (
                <FieldIconBase team={team} animationName={icon} animationDataMap={animationDataMap} />
            )}
        </div>
    );
});

FieldTextBase.displayName = 'FieldTextBase';

FieldTextBase.propTypes = {
    icon: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
    homeTeam: PropTypes.string.isRequired,
    awayTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    animationDataMap: PropTypes.shape({}).isRequired,
};
