import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { AnimationMap } from 'features/floorball/constants';
import { useRemovalStatistics } from 'features/floorball/hooks';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function RemovalEndAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();
    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();
    const { awayStat, homeStat, label: subText } = useRemovalStatistics();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} halfCircle />
            <FieldText
                title={translate('action.removalEnd.animation')}
                subText={subText}
                team={team}
                homeStat={homeStat}
                awayStat={awayStat}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                icon="removalEnd"
            />
            <FieldIcon animationName="removalEnd" team={team} />
        </div>
    );
}

RemovalEndAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};

RemovalEndAnimation.ANIMATION_NAME = AnimationMap.RemovalEnd;
