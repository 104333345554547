import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCallbackTL, useNodeTimeline, useRectTimeline } from 'hooks';
import { AnimationMap } from 'features/football/constants';
import { useAttackStatistic } from 'features/football/hooks';
import { FieldAttackArrow } from './common/FieldAttackArrow';
import { FieldBallPoint } from './common/FieldBallPoint';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function AttackAnimation(props) {
    const { team } = props;
    const [translate] = useTranslation();

    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    const { awayStat, homeStat, label: subtext } = useAttackStatistic();

    return (
        <div ref={nodeRef}>
            <FieldAttackArrow team={team} ref={rectRef} />
            <FieldText
                title={translate('action.attack.animation')}
                subText={subtext}
                team={team}
                homeStat={homeStat}
                awayStat={awayStat}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                icon="attack"
                isPercent
            />
            <FieldIcon animationName="attack" team={team} />
            <FieldBallPoint team={team} />
        </div>
    );
}

AttackAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};
AttackAnimation.ANIMATION_NAME = AnimationMap.Attack;
