import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCallbackTL, useNodeTimeline } from 'hooks';
import { FullRectangle } from 'ui/atoms/FullRectangle/FullRectangle';
import { AnimationMap } from 'features/iceHockey/constants';
import { IconText } from './common/IconText';

export function FaceOffAnimation() {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);

    return (
        <div ref={nodeRef}>
            <FullRectangle />
            <IconText icon="faceOff" text={translate('action.faceOff.animation')} />
        </div>
    );
}

FaceOffAnimation.ANIMATION_NAME = AnimationMap.FaceOff;
