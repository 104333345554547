/* eslint-disable max-len */
import * as React from 'react';

import { useTargetName } from 'hooks';

const RemovalEnd = (props) => {
    const { classMap: icon } = useTargetName((targets) => ({
        [targets.MOSTBET]: (
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M20 10c0 1.18-.2 2.33-.6 3.42-.19.52-.76.79-1.28.6a.998.998 0 0 1-.6-1.28c.32-.88.48-1.8.48-2.74 0-4.41-3.59-8-8-8s-8 3.59-8 8 3.59 8 8 8c.94 0 1.85-.16 2.72-.48a.998.998 0 0 1 1.28.6.998.998 0 0 1-.6 1.28c-1.09.4-2.23.6-3.4.6-5.51 0-10-4.49-10-10S4.49 0 10 0s10 4.49 10 10Zm-5.76-4.24a.996.996 0 0 0-1.41 0L10 8.59 7.17 5.76a.996.996 0 1 0-1.41 1.41L8.59 10l-2.83 2.83a.996.996 0 0 0 .71 1.7c.26 0 .51-.1.71-.29L10 11.41l2.83 2.83c.2.2.45.29.71.29.26 0 .51-.1.71-.29a.996.996 0 0 0 0-1.41L11.41 10l2.83-2.83a.996.996 0 0 0 0-1.41Z"
                    fill="#256569"
                />
            </svg>
        ),
        [targets.BETANDREAS]: (
            <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0Zm3.24 10.83a1 1 0 0 1-.326 1.626 1 1 0 0 1-.384.074 1 1 0 0 1-.71-.29L9 10.41l-1.83 1.83a1 1 0 0 1-.71.29 1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.41L7.59 9 5.76 7.17a1 1 0 1 1 1.41-1.41L9 7.59l1.83-1.83a1 1 0 1 1 1.41 1.41L10.41 9l1.83 1.83ZM16 10a1 1 0 0 1-1-1 6 6 0 1 0-6 6 1 1 0 0 1 0 2 8 8 0 1 1 8-8 1 1 0 0 1-1 1Z"
                    fill="#9393A9"
                />
            </svg>
        ),
        [targets.VIVI]: (
            <svg {...props} width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.15 9.23a6.15 6.15 0 1 0-12.3 0 6.15 6.15 0 0 0 12.3 0ZM4.54.63h4.92M11.92 3.08l-1.23 1.23M4.17 9h5.66"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
        [targets['MOSTBET-2']]: (
            <svg {...props} width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_23406_494723)">
                    <path
                        d="M13.02 4.65L13.89 3.78C14.09 3.58 14.09 3.27 13.89 3.07C13.69 2.87 13.38 2.87 13.18 3.07L12.31 3.94C11.23 3.01 9.86 2.42 8.36 2.31V1H9.13C9.41 1 9.63 0.78 9.63 0.5C9.63 0.22 9.41 0 9.13 0H6.59C6.31 0 6.09 0.22 6.09 0.5C6.09 0.78 6.31 1 6.59 1H7.36V2.31C5.85 2.42 4.47 3.02 3.38 3.96L2.49 3.07C2.29 2.87 1.98 2.87 1.78 3.07C1.58 3.27 1.58 3.58 1.78 3.78L2.67 4.67C1.63 5.87 1 7.43 1 9.15C1 12.93 4.08 16.01 7.86 16.01C11.64 16.01 14.72 12.93 14.72 9.15C14.72 7.43 14.08 5.86 13.03 4.65H13.02ZM7.86 15C4.63 15 2 12.37 2 9.14C2 5.91 4.63 3.29 7.86 3.29C11.09 3.29 13.72 5.92 13.72 9.15C13.72 12.38 11.09 15.01 7.86 15.01V15Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.5 9C4.5 9.27614 4.72386 9.5 5 9.5H10.6569C10.9331 9.5 11.1569 9.27614 11.1569 9C11.1569 8.72386 10.9331 8.5 10.6569 8.5H5C4.72386 8.5 4.5 8.72386 4.5 9Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_23406_494723">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        ),
    }));

    return icon;
};

export default RemovalEnd;
