import React from 'react';
import { Lottie, useLottie } from 'react-lottie-hook';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useTargetName } from 'hooks';
import { animationDataMap } from 'features/iceHockey/animations/data';

export function FieldIcon(props) {
    const { animationName, team } = props;

    const [lottieRef] = useLottie({
        renderer: 'svg',
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid',
            progressiveLoad: false,
        },
        animationData: animationDataMap[animationName],
    });

    const { conditions } = useTargetName();

    if (conditions.isVivi || conditions.isMostbetV2) {
        return null;
    }

    return (
        <div className={cn('field_icon', team === 1 ? 'field_icon--left' : 'field_icon--right')}>
            <Lottie lottieRef={lottieRef} width="100%" height="100%" />
        </div>
    );
}

FieldIcon.propTypes = {
    animationName: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
};
