import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { useCallbackTL, useNodeTimeline, useTargetName } from 'hooks';
import { FullRectangle } from 'ui/atoms/FullRectangle/FullRectangle';
// eslint-disable-next-line import/no-cycle
import { RootMachineContext } from 'features/common/providers/RootMachineProvider';
import { AnimationMap } from 'features/volleyball/constants';

export function PeriodStartAnimation() {
    const [translate] = useTranslation();
    const { current } = useContext(RootMachineContext);
    const { periods } = current.context.store.translationData.statistics;
    const { conditions } = useTargetName();

    const nodeRef = useNodeTimeline();
    useCallbackTL(nodeRef);
    return (
        <div className="h-full volleyball-animation" ref={nodeRef}>
            <FullRectangle>
                <p className="animation__field--text-action">{translate('time.setStart')}</p>
                <p
                    className={cn(
                        conditions.isMostbet ? 'volleyball-text-primary-light' : 'text-secondary-light',
                        'animation__field--additional-text',
                    )}
                >
                    {translate('time.set', { set: periods.length })}
                </p>
            </FullRectangle>
        </div>
    );
}

PeriodStartAnimation.ANIMATION_NAME = AnimationMap.PeriodStart;
