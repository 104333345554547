import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useTargetName, useTeamNumbers } from 'hooks';
import { FieldTextBase } from 'ui';
import { animationDataMap } from 'features/bigTennis/animations/data';
import { FieldIcon } from './FieldIcon';

export function WithRefFieldText(props, ref) {
    const { icon, team, title, homeTeam, awayTeam, iconReversed } = props;
    const { conditions } = useTargetName();
    const [homeTeamNumber] = useTeamNumbers();
    const isHome = team === homeTeamNumber;
    const wrapperCls = cx('field-text_wrapper', {
        'flex-row-reverse': isHome,
        _left: isHome,
        _right: !isHome,
    });

    const txtCls = cx('flex flex-col h-1/2 justify-center p-3 ', {
        'items-end text-right': isHome,
        'items-start text-left': !isHome,
    });
    const teamCls = cx('big-tennis-team-text animation__field--additional-text', {
        'text-right': isHome,
        'text-left': !isHome,
    });

    if (conditions.isVivi || conditions.isMostbetV2) {
        return <FieldTextBase {...props} animationDataMap={animationDataMap} ref={ref} />;
    }

    return (
        <div className={wrapperCls} ref={ref}>
            <FieldIcon team={team} animationName={icon} isReversed={iconReversed} />
            <div className={txtCls}>
                <p className="animation__field--text-action">{title}</p>
                <p className={teamCls}>{isHome ? homeTeam : awayTeam}</p>
            </div>
        </div>
    );
}

export const FieldText = React.forwardRef(WithRefFieldText);

WithRefFieldText.propTypes = {
    icon: PropTypes.string.isRequired,
    team: PropTypes.number.isRequired,
    homeTeam: PropTypes.string.isRequired,
    awayTeam: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    iconReversed: PropTypes.bool,
};

WithRefFieldText.defaultProps = {
    iconReversed: true,
};
