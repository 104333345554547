import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNodeTimeline } from 'hooks';
import { FullRectangle } from 'ui/atoms/FullRectangle/FullRectangle';
import { AnimationMap } from 'features/badminton/constants';

export function ChallengeAnimation() {
    const [translate] = useTranslation();
    const nodeRef = useNodeTimeline();
    return (
        <div className="h-full" ref={nodeRef}>
            <FullRectangle>
                <p className="animation__field--text-action">{translate('animation.challenge')}</p>
            </FullRectangle>
        </div>
    );
}

ChallengeAnimation.ANIMATION_NAME = AnimationMap.Challenge;
