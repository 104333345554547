import React from 'react';
import PropTypes from 'prop-types';

import { useAnimationProps } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { AnimationMap } from 'features/futsal/constants';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function YellowCardAnimation(props) {
    const { team } = props;
    const { translate, nodeRef, rectRef, store, statistics } = useAnimationProps();

    const { homeTeam: HOME, awayTeam: AWAY } = store;
    const { yellowCards } = statistics;
    const homeLen = yellowCards[HOME].length;
    const awayLen = yellowCards[AWAY].length;

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} isYellow ref={rectRef} halfCircle />
            <FieldText
                isYellow
                title={translate('action.yellowCard.animation')}
                subText={translate('statistics.yellowCards')}
                homeStat={homeLen}
                awayStat={awayLen}
                team={team}
                homeTeam={translate('team1')}
                awayTeam={translate('team2')}
                icon="yellowCard"
            />
            <FieldIcon animationName="yellowCard" team={team} />
        </div>
    );
}

YellowCardAnimation.propTypes = {
    team: PropTypes.number.isRequired,
};
YellowCardAnimation.ANIMATION_NAME = AnimationMap.YellowCard;
