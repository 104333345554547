/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import { useTargetName } from 'hooks';

export function FieldGoalFutsal({ contourColor, mainColor, ...otherProps }) {
    const { classMap: icon } = useTargetName((targets) => ({
        [targets.MOSTBET]: (
            <svg {...otherProps} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                    fill={mainColor}
                    fillRule="evenodd"
                    d="M10 1a9 9 0 019 9 9 9 0 01-9 9 9 9 0 01-9-9 9 9 0 019-9z"
                />
                <path
                    fill={contourColor}
                    fillRule="evenodd"
                    d="M17.071 17.071A9.93 9.93 0 0110 20a9.934 9.934 0 01-7.071-2.929A9.932 9.932 0 010 10a9.93 9.93 0 012.929-7.071A9.934 9.934 0 0110 0a9.93 9.93 0 017.071 2.929A9.93 9.93 0 0120 10a9.932 9.932 0 01-2.929 7.071zM15.696 15.7l-1.546.012-.491 1.471a8.155 8.155 0 002.037-1.483zM10 18.063c.455 0 .913-.039 1.361-.116l1.376-4.12H7.263l1.375 4.12a8.07 8.07 0 001.362.116zm2.185-8.741L10 7.735 7.815 9.322l.834 2.569h2.701l.835-2.569zm-5.844 7.861l-.492-1.471-1.545-.012a8.155 8.155 0 002.037 1.483zM2.817 6.34a8.077 8.077 0 00-.781 2.401l1.249-.924-.468-1.477zM10 1.937c-.423 0-.846.033-1.263.1L10 2.939l1.264-.902a7.97 7.97 0 00-1.264-.1zm3.569.834L10 5.32 6.432 2.771a8.032 8.032 0 00-2.203 1.606l1.32 4.177-3.528 2.606c.131.908.415 1.778.844 2.591l4.364.036-1.691-5.205L10 5.34l4.462 3.242-1.691 5.205 4.365-.036a7.98 7.98 0 00.844-2.591l-3.529-2.606 1.32-4.177a8.037 8.037 0 00-2.202-1.606zm3.613 3.569l-.466 1.477 1.247.924a8.02 8.02 0 00-.781-2.401z"
                />
            </svg>
        ),
        [targets.BETANDREAS]: (
            <svg viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...otherProps}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.448 1.184a8.947 8.947 0 0 1 2.918 1.958c3.512 3.507 3.512 9.212.001 12.717A8.947 8.947 0 0 1 9.001 18.5a8.96 8.96 0 0 1-6.368-2.642c-3.51-3.506-3.51-9.211 0-12.716A8.953 8.953 0 0 1 9.001.5a8.957 8.957 0 0 1 3.447.684ZM8.84 2.306h.324c-.026 0-.051-.002-.076-.004-.028-.002-.056-.004-.086-.004-.029 0-.057.002-.086.004a.942.942 0 0 1-.076.004Zm3.762 11.69h2.02a7.146 7.146 0 0 0 1.454-3.167l-1.673-2.228 1.095-2.19a7.138 7.138 0 0 0-1.403-1.998 7.214 7.214 0 0 0-2.9-1.77L9 4.106 6.805 2.643a7.2 7.2 0 0 0-2.899 1.77 7.136 7.136 0 0 0-1.404 2l1.095 2.188-1.673 2.228a7.153 7.153 0 0 0 1.462 3.176h2.012l1.13 2.252a7.174 7.174 0 0 0 2.473.445 7.173 7.173 0 0 0 2.468-.444l1.132-2.262ZM7.286 11.5 6 8.423 9 6.5l3 1.923-1.286 3.077H7.286Z"
                    fill="#fff"
                />
            </svg>
        ),
        [targets['MOSTBET-2']]: (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none">
                <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd" fill="#fff">
                    <path d="M7.92 14.95A6.97 6.97 0 0 1 .97 8a6.97 6.97 0 0 1 6.95-6.95A6.97 6.97 0 0 1 14.87 8a6.97 6.97 0 0 1-6.95 6.95ZM-.02 8a7.96 7.96 0 0 0 7.94 7.94A7.96 7.96 0 0 0 15.86 8 7.96 7.96 0 0 0 7.92.06 7.96 7.96 0 0 0-.02 8Z" />
                    <path d="M7.45 11.45c.17.13.41.13.58 0l2.98-2.15a.5.5 0 0 0 .18-.56l-1.14-3.5a.5.5 0 0 0-.47-.33H5.91a.5.5 0 0 0-.47.34L4.3 8.74a.5.5 0 0 0 .18.55l2.97 2.16Zm.3-1.01L5.34 8.7l.92-2.8h2.95l.46 1.4.45 1.4-1.2.87-1.19.87ZM4.02 14.8c.11.25.4.37.65.26l3.25-1.4 3.24 1.4a.5.5 0 1 0 .4-.9l-3.45-1.5a.5.5 0 0 0-.4 0l-3.43 1.5a.5.5 0 0 0-.26.64Zm-1.4-1.03a.5.5 0 0 0 .53-.45l.36-3.72a.5.5 0 0 0-.13-.37L1.07 6.6a.5.5 0 1 0-.75.66L2.5 9.71l-.33 3.5a.5.5 0 0 0 .44.55Zm10.6 0a.5.5 0 0 0 .45-.54l-.34-3.51 2.2-2.48a.5.5 0 0 0-.75-.66l-2.33 2.65a.5.5 0 0 0-.12.37l.35 3.72a.5.5 0 0 0 .54.45ZM.82 4.7a.5.5 0 0 0 .58.37l3.6-.8a.5.5 0 0 0 .31-.23l1.76-2.98a.5.5 0 0 0-.85-.5l-1.65 2.8-3.38.75a.5.5 0 0 0-.38.59Zm14.13-.02a.5.5 0 0 0-.38-.59l-3.3-.74L9.51.4a.5.5 0 0 0-.86.5l1.86 3.15c.07.12.19.2.32.23l3.52.79a.5.5 0 0 0 .59-.38Z" />
                    <path d="M6.17 5.82a.5.5 0 0 0 .16-.69L5.3 3.53a.5.5 0 1 0-.84.52L5.5 5.66c.15.23.45.3.68.16ZM9.26 5.78c.2.18.52.15.7-.06l1.37-1.61a.5.5 0 1 0-.76-.64L9.2 5.08a.5.5 0 0 0 .06.7ZM13.29 9.7a.5.5 0 0 0-.32-.62l-2.11-.66a.5.5 0 1 0-.3.95l2.11.66a.5.5 0 0 0 .62-.33ZM7.96 13.6a.5.5 0 0 0 .45-.53l-.18-2.06a.5.5 0 0 0-.98.08l.18 2.07a.5.5 0 0 0 .53.45ZM2.55 9.73c.1.25.38.38.64.29l1.75-.67a.5.5 0 1 0-.34-.92l-1.76.66a.5.5 0 0 0-.29.64Z" />
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill="#fff" d="M0 0h16v16H0z" />
                    </clipPath>
                </defs>
            </svg>
        ),
        [targets.VIVI]: (
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...otherProps}
            >
                <path
                    d="M15.375 8.00002C15.375 12.0731 12.0731 15.375 7.99998 15.375C3.9269 15.375 0.625 12.0731 0.625 8.00002C0.625 3.92694 3.9269 0.625043 7.99998 0.625043C12.0731 0.625043 15.375 3.92694 15.375 8.00002Z"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.87103 6.7715L7.99999 5.54234L10.129 6.7715V9.22983L7.99999 10.459L5.87103 9.22983V6.7715Z"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.4994 11.4892L13.8512 10.841L11.4766 11.4773L10.8403 13.8518L11.4885 14.5"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.50061 4.51197L2.1488 5.16016L4.52338 4.52388L5.15963 2.14932L4.51145 1.50114"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.50061 11.4892L2.1488 10.841L4.52338 11.4773L5.15963 13.8518L4.51145 14.5"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.4994 4.51197L13.8512 5.16016L11.4766 4.52388L10.8403 2.14932L11.4885 1.50114"
                    stroke="#444188"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        ),
    }));

    return icon;
}

FieldGoalFutsal.defaultProps = {
    contourColor: 'rgb(94, 134, 9)',
    mainColor: 'rgb(255, 255, 255)',
};

FieldGoalFutsal.propTypes = {
    contourColor: PropTypes.string,
    mainColor: PropTypes.string,
};
