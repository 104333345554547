import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useNodeTimeline, useRectTimeline } from 'hooks';
import { HalfRectangle } from 'ui/atoms/HalfRectangle/HalfRectangle';
import { AnimationMap } from 'features/rugby/constants';
import { FieldIcon } from './common/FieldIcon';
import { FieldText } from './common/FieldText';

export function TryAnimation(props) {
    const { team, score } = props;

    const [translate] = useTranslation();
    const rectRef = useRectTimeline();
    const nodeRef = useNodeTimeline();

    return (
        <div ref={nodeRef}>
            <HalfRectangle team={team} ref={rectRef} halfCircle />
            <FieldText
                title={translate('action.try.animation')}
                team={team}
                isWithoutStats
                // homeStat={homePercent}
                // awayStat={awayPercent}
                homeTeam={`${translate('team1')} (+${score})`}
                awayTeam={`${translate('team2')} (+${score})`}
                isPercent
                icon="trying"
            />
            <FieldIcon animationName="trying" team={team} />
        </div>
    );
}

TryAnimation.propTypes = {
    team: PropTypes.number.isRequired,
    score: PropTypes.number.isRequired,
};
TryAnimation.ANIMATION_NAME = AnimationMap.Try;
